.history {
    display: flex;
    flex-direction: column;

    background-color: var(--dark1);
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.4);
    border-top: 1px solid var(--dark2);
    padding: 1rem 0;
    gap: 16px;

    width: 100%;
    max-width: 400px;
    height: 100%;
    overflow: hidden;
}

.history > * {
    padding: 0 1rem;
}

.card {
    display: flex;
    flex-direction: column;

    border: 1px solid var(--dark1);
    border-radius: 4px;

    font-size: 12px;
    line-height: 18px;

    background-color: var(--dark3);
    color: var(--text1);
    padding: 1rem;
}

.card_status {
    display: flex;
    flex-direction: column;
    padding: 0px 8px;

    cursor: pointer;
    margin-top: 0.5rem;
}

.card_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.card_status_summary {
    display: flex;
    flex-direction: row;
    padding: 0 8px;
}

.card_status_details {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
    padding-top: 4px;
}

.card_line {
    display: flex;
    justify-content: space-between;

    gap: 16px;
}

.card_line > div:first-child {
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card_line > div:nth-child(2) {
    min-width: max-content;
}

.scrollable {
    display: flex;
    flex-direction: column;
    gap: 8px;

    flex: 1;
    scrollbar-color: var(--dark3) var(--dark1);
    overflow-y: auto;

    padding-bottom: 2rem;
}

.loading {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.show_more {
    cursor: pointer;
    text-decoration: underline;
    padding: 4px;
    width: max-content;
}
