.restart_button {
    margin-top: 2px;
    margin-bottom: 2px;
    height: auto;
}

.restart_button:active {
    background-color: darkgray;
    pointer-events: none;
}

.restart_button:disabled {
    background-color: darkgray;
    pointer-events: none;
}
