.login {
    width: 100%;
    max-width: 400px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;

    background-color: var(--dark1);

    border: 2px solid var(--dark3);
    border-radius: 4px;

    align-self: center;
}

.flex_container {
    display: flex;
    flex-direction: column;

    gap: 4px;
}

.full_width {
    width: 100%;
}

.error {
    font-size: 12px;
    color: var(--danger);
}

.resend_code {
    width: fit-content;
    cursor: pointer;
}

.resend_code:hover {
    text-decoration: underline;
}
