.modal {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.filter_checks {
    column-count: 2; /* Split into 2 columns */
    column-gap: 20px; /* Gap between columns */
    list-style-type: none; /* Remove bullet points */
    padding: 0;
    margin: 0;
    text-align: left;

    padding-bottom: 1rem;
}

.filter_checks label {
    cursor: pointer;
}

.download {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.loading {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
