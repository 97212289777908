.container {
    height: 100vh;
    width: 100vw;

    background-color: var(--dark2);

    display: flex;
    flex-direction: column;

    overflow: hidden;
}

.content {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;
}

.loading_container {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.main {
    flex: 1;

    display: flex;
    flex-direction: column;

    padding: 1rem 0;
    gap: 1rem;

    overflow: hidden;

    -webkit-user-select: text; /* Safari */
    -ms-user-select: text; /* IE 10 and IE 11 */
    user-select: text; /* Standard syntax */
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    margin-right: 1rem;
}

.title_and_description {
    display: flex;
    align-items: baseline;
}

.description {
    color: var(--text4);
    size: '16px';
    gap: 1rem;
    margin-right: 1rem;
}

.table_container {
    overflow-x: auto;
    scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.table-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers (Chrome, Safari) */
}

.table {
    width: 100%;
    border-spacing: 0;
}

.table td {
    max-width: 25vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0px 8px;
}

tr td:first-child {
    padding: 0;
}

.table tr:nth-child(odd) {
    background-color: var(--dark2);
}

.table tr:nth-child(even) {
    background-color: var(--dark2-alt);
}

.loading {
    display: flex;
    justify-content: center;
    min-height: 4rem;
    margin-top: 2rem;
}

.section {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: hidden;
}

.main header {
    padding: 0 1rem;
}

.section_header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    color: var(--text2);
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    margin: 0 1rem;
    border-bottom: 1px solid var(--text1);
    margin-bottom: 0.5rem;
}

.section_content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;

    color: var(--text1);
    font-size: 14px;

    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
}

.section_content p {
    padding: 0.5rem 1rem;
}

.title_container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.game_status {
    display: flex;
    align-items: center;
    gap: 8px;
}

.status {
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
}

.success {
    background-color: var(--success);
}

.warn {
    background-color: var(--warn);
}

.danger {
    background-color: var(--danger);
}

.sticky {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    right: 0;
    width: 1%;
    white-space: nowrap;
    background-color: inherit;
    padding-left: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.icons {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}

.icon {
    cursor: pointer;
}

.flex_container {
    display: flex;
    flex-direction: column;

    gap: 4px;
}

.error {
    font-size: 12px;
    color: var(--danger);
}

.close {
    cursor: pointer;
    margin-right: 1rem;
}

.checkbox label {
    cursor: default;
}

.checkbox_container {
    display: flex;
    gap: 8px;
}

.modal {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.modal_button_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
}

.sortable {
    cursor: pointer;
}

.sortable:hover {
    text-decoration: underline;
}

.account_button_group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.actions {
    display: flex;
}

.max {
    width: 100%;
}

.user_setting {
    display: flex;
    gap: 4px;
    flex-direction: column;
    align-self: center;
    width: 200px;
    padding: 20px 0;
}

.user_setting > button {
    width: 100%;
}

.general_textarea {
    width: 100%;
    height: 100%;
    resize: none;
    background-color: var(--dark2-alt);
    color: var(--text1);
}

button.flat {
    height: auto;
}
