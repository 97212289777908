.modal_container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;

    backdrop-filter: blur(16px);
}

.modal {
    z-index: 1000;
    width: 100%;
    max-width: 400px;

    margin: auto;
    background-color: var(--dark1);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    color: var(--text2);

    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 16px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal header {
    padding: 0 !important;
}
