.main {
    flex: 1;

    display: flex;
    flex-direction: row;

    overflow: hidden;
}

.main_container {
    flex: 1;

    display: flex;
    flex-direction: column;

    padding: 1rem;
    gap: 1rem;

    overflow-y: auto;
}

.loading {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8rem;
}

.padding {
    padding: 0 1rem 0.5rem;
}

.section {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.section_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: var(--text2);
    padding: 5rem;
    margin: 0 1rem;
    margin-bottom: 0.5rem;
}

.server_btn {
    display: flex;
    align-items: center;
    gap: 8px;
}
