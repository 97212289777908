@import url('https://fonts.googleapis.com/css2?family=Glegoo:wght@100;200;300;400;500;700&display=swap');

body {
    margin: 0;
    font-family: 'Glegoo', serif;
    font-weight: 700;
    letter-spacing: 0.5px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    scrollbar-color: var(--dark3) var(--dark2);

    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

:root {
    --font-family: 'Glegoo', serif;

    --dark1: #161a21;
    --dark2: #33383d;
    --dark2-alt: #3f454c;
    --dark3: #21272d;

    --text1: #e0e6eb;
    --text2: #cab5e8;
    --text3: #b1b7ff;
    --text4: #abb2b9;
    --text-plive: #b8edff;
    --text-pezlive: #a0c8d6;
    --text-dynamic: #dba0bb;
    --text-ultra: #c3ffc8;
    --text-ghetto: #a4d9a8;
    --text-metallic: #e1b073;
    --text-cris: #f08feb;

    --accent1: #690c8a;
    --accent2: #9766e0;
    --accent3: #747de8;
    --accent4: #67747d;
    --accent-plive: #74c7e8;
    --accent-pezlive: #5d94aa;
    --accent-dynamic: #aa3b6d;
    --accent-ultra: #74e87e;
    --accent-ghetto: #97bd99;
    --accent-metallic: #f79951;
    --accent-cris: #e457dd;

    --success: #33a800;
    --warn: #b59610;
    --danger: #c22c2c;

    --text-success: #7ecb5d;
    --text-warn: #e7cc55;
    --text-danger: #fd7a7a;
}

::-webkit-scrollbar {
    width: 0px;
    /* Width of the vertical scrollbar */
}

header {
    color: var(--text2);
    font-size: 18px;
    line-height: 24px;
}

label,
p {
    color: var(--text1);
    font-size: 12px;
    line-height: 16px;
    cursor: inherit;
    margin: 0;
}

input {
    background-color: var(--dark3);
    border-radius: 4px;
    border: none;
    height: 2rem;
    padding: 0 8px;
    color: var(--text1);
    font-family: var(--font-family);
    letter-spacing: 0.5px;
    line-height: 10px;
}

input:focus-visible {
    outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

input:disabled {
    background-color: var(--dark2);
}

input[type='checkbox'] {
    accent-color: var(--dark3);
    height: auto;
    vertical-align: middle;
    margin-right: 8px;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

select {
    background-color: var(--dark3);
    border-radius: 4px;
    border: none;
    height: 2rem;
    width: 100%;
    padding: 0 8px;
    color: var(--text1);
    font-family: var(--font-family);
    letter-spacing: 0.5px;
    line-height: 10px;
    /* add padding to the arrow*/
    border-right: 8px solid transparent;
}

select:focus-visible {
    outline: none;
}

select option {
    width: max-content;
}

button {
    height: 2.5rem;
    background-color: var(--accent1);
    color: var(--text1);
    font-family: var(--font-family);
    font-weight: 700;
    letter-spacing: 0.5px;

    border: none;
    border-radius: 4px;

    cursor: pointer;

    padding: 0 1rem;
}

button:hover {
    opacity: 0.8;
}

button:disabled {
    cursor: default;
    background-color: var(--dark3);
    opacity: 1;
}

progress {
    -webkit-appearance: none;
    appearance: none;

    height: 0.5rem;
}

progress::-webkit-progress-bar {
    background-color: var(--dark2);
    height: 0.5rem;
    border-radius: 10px;
}

progress::-webkit-progress-value {
    background-color: var(--accent1);
    height: 0.5rem;
    border-radius: 10px;
}

progress::-moz-progress-bar {
    background-color: var(--dark2);
    height: 0.5;
    border-radius: 10px;
}

td {
    padding: 0 4px;
}

td > svg {
    vertical-align: middle;
}

a {
    color: var(--text1);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
