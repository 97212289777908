.sidebar {
    width: 300px;
    background-color: var(--dark1);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: column;

    padding: 1rem 0;
    gap: 16px;
}

.sidebar > header {
    padding: 0 1rem;
}

.sidebar > div {
    overflow: auto;
}

.p {
    padding: 0.5rem;
    color: var(--text1);
    font-size: 12px;
    border-bottom: 1px solid var(--dark2);

    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 8px;
}

.button {
    padding: 1rem;
}

.button:hover {
    cursor: pointer;
    background-color: var(--dark2);
}

.selected {
    background-color: var(--dark2) !important;
}

.league {
    padding-left: 1.5rem;
    background-color: var(--dark3);
}
