.sidebar {
    width: 200px;
    height: 100%;
    background-color: var(--dark1);
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.sidebar > header {
    padding: 8px;
}

.button_group {
    display: flex;
    flex-direction: column;
}
.button_group > button {
    background-color: var(--dark1);
    border-radius: 0%;
    font-size: medium;
}
.button_group > button:hover {
    background-color: var(--dark3);
}

.title {
    color: var(--text2);
    font-size: 18px;
    line-height: 24px;
    padding: 1rem;
}
