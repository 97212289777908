.game {
    border-bottom: 1px solid var(--dark3);
}

.button {
    padding: 1rem;
    cursor: pointer;
    color: var(--text1);
    font-size: 12px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    gap: 4px;
}

.button:hover {
    background-color: var(--dark3);
}

.padding {
    padding: 0.5rem 2rem;
}

.loading {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
}
