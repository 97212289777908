.notification_container {
    position: absolute;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    gap: 1rem;
    padding: 1rem;
}

.notification {
    height: 2rem;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    background-color: var(--dark1);
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.4);
    padding: 0.5rem 1rem;
    border-radius: 4px;
}

.close {
    color: var(--text1);
    cursor: pointer;
    flex-shrink: 0;
    margin-right: -4px;
}

.check {
    color: var(--accent1);
    padding-right: 0.5rem;
}
