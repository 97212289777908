.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    color: var(--text1);
}

.close {
    cursor: pointer;
    padding: 6px 0;
    flex-shrink: 0;
}

.modal {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.details {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    background-color: var(--dark2);
    padding: 12px;
}

.details_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.details_row > input {
    width: 64px;
    text-align: right;
}

.details_row > input::placeholder {
    color: var(--text1);
}

.highlight::placeholder {
    color: var(--text2) !important;
}

.accounts {
    display: flex;
    flex-direction: column;

    gap: 8px;
}

.filters,
.amount {
    display: flex;
    flex-direction: row;

    gap: 4px;
}

.filters {
    flex-wrap: wrap;
    max-height: 160px;
}

.button {
    cursor: pointer;
    font-size: 12px;
    padding: 0 4px;
    border-radius: 4px;
}

.button:hover {
    color: var(--text1);
}

.button_all {
    color: var(--text2);
    border: 1px solid var(--accent2);
}

.button_tag {
    color: var(--text3);
    border: 1px solid var(--accent3);
}

.button_family {
    color: var(--text4);
    border: 1px solid var(--accent4);
}

.selected_all {
    background-color: var(--accent2);
}

.selected_tag {
    background-color: var(--accent3);
}

.selected_family {
    background-color: var(--accent4);
}

.sites {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-left: 8px;
}

.amount input {
    flex: 1;
}

.submit {
    display: flex;
    flex-direction: column;

    gap: 4px;
}

.error {
    font-size: 10px;
    color: var(--danger);
}
