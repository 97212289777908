.reports {
    flex: 1;

    display: flex;
    flex-direction: column;

    padding: 1rem 0;
    gap: 1rem;

    overflow: hidden;

    -webkit-user-select: text;
    /* Safari */
    -ms-user-select: text;
    /* IE 10 and IE 11 */
    user-select: text;
    /* Standard syntax */
}

.reports > *,
.container > *,
.bet_card_container > * {
    padding: 0 1rem;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.period_selector {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.selector {
    width: max-content;
}

.loading {
    flex: 1;
    display: flex;
    justify-content: center;
    min-height: 4rem;
    margin-top: 2rem;
}

.sidebar {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar_content {
    padding: 0.5rem 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}

.sidebar_content > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.download {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.content {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.container {
    padding: 0;
    padding-left: 1rem;
    flex: 1;
    color: var(--text2);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.filters {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    gap: 4px;
}

.filter_buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.filter_button_container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 4px;
    padding-right: 8px;
}

.button {
    cursor: pointer;
    font-size: 12px;
    padding: 0 4px;
    border-radius: 4px;

    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.button:hover {
    color: var(--text1);
}

.button_success {
    color: var(--text-success);
    border: 1px solid var(--success);
}

.button_warn {
    color: var(--text-warn);
    border: 1px solid var(--warn);
}

.button_danger {
    color: var(--text-danger);
    border: 1px solid var(--danger);
}

.button_neutral,
.button_source {
    color: var(--text4);
    border: 1px solid var(--accent4);
}

.button_status {
    color: var(--text2);
    border: 1px solid var(--accent2);
}

.button_user {
    color: var(--text3);
    border: 1px solid var(--accent3);
}

.button_dynamic {
    color: var(--text-dynamic);
    border: 1px solid var(--accent-dynamic);
}

.button_plive {
    color: var(--text-plive);
    border: 1px solid var(--accent-plive);
}

.button_pezlive {
    color: var(--text-pezlive);
    border: 1px solid var(--accent-pezlive);
}

.button_ultra {
    color: var(--text-ultra);
    border: 1px solid var(--accent-ultra);
}

.button_metallic {
    color: var(--text-metallic);
    border: 1px solid var(--accent-metallic);
}

.button_cris {
    color: var(--text-cris);
    border: 1px solid var(--accent-cris);
}

.button_ghetto {
    color: var(--text-ghetto);
    border: 1px solid var(--text-ghetto);
}

.selected_success {
    background-color: var(--success);
}

.selected_warn {
    background-color: var(--warn);
}

.selected_danger {
    background-color: var(--danger);
}

.selected_neutral,
.selected_source {
    background-color: var(--accent4);
}

.selected_status {
    background-color: var(--accent2);
}

.selected_user {
    background-color: var(--accent3);
}

.selected_dynamic {
    background-color: var(--accent-dynamic);
}

.selected_plive {
    background-color: var(--accent-plive);
}

.selected_pezlive {
    background-color: var(--accent-pezlive);
}

.selected_ultra {
    background-color: var(--accent-ultra);
}

.selected_metallic {
    background-color: var(--accent-metallic);
}

.selected_cris {
    background-color: var(--accent-cris);
}

.selected_ghetto {
    background-color: var(--accent-ghetto);
}

.dropdown_container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.dropdown {
    align-self: flex-end;
}

.bet_card_container,
.scrollable {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
}

.bet_card_container {
    overflow: hidden;
    padding: 0.5rem 0;
}

.scrollable {
    overflow-y: auto;
}

.bet_card,
.summary_card {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: var(--dark3);
}

.summary_card {
    cursor: pointer;
}

.bet_details,
.bet_group_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
}

.bet_account_info > span {
    padding: 0 0.25rem;
}

.bet_group_info svg {
    padding-left: 0.5rem;
}

.bet_group_info > p,
.bet_details > p {
    flex: 2;
    text-align: center;
}

.bet_details > p {
    margin-top: 8px;
}

.bet_group_info > p:first-child,
.bet_details > p:first-child {
    text-align: left;
    flex: 1;
}

.bet_group_info > p:last-child,
.bet_details > p:last-child {
    text-align: right;
    flex: 1;
}

.odds_info,
.account_info,
.bet_info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.account_info {
    align-items: flex-end;
}

.bet_info {
    align-items: center;
}

.show_more {
    cursor: pointer;
    text-decoration: underline;
    padding: 4px;
    width: max-content;
}

.modal {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.checkbox label {
    cursor: default;
}

.checkbox input {
    height: auto;
    vertical-align: middle;
    margin-right: 8px;
}

.checkbox {
    display: flex;
    gap: 8px;
}
