.odds {
    display: flex;
    flex-direction: column;

    gap: 8px;
    padding: 0.5rem 2rem;
    border-bottom: 1px solid var(--dark2);
}

.filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    gap: 4px;
}

.spacer {
    width: 100%;
    grid-column: 1 / -1;
}

.button_categories,
.button_groups,
.button_providers {
    cursor: pointer;
    font-size: 12px;
    padding: 0 4px;
    border-radius: 4px;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.button_categories:hover,
.button_groups:hover,
.button_providers:hover {
    color: var(--text1);
}

.button_categories {
    color: var(--text2);
    border: 1px solid var(--accent2);
}

.button_groups {
    color: var(--text3);
    border: 1px solid var(--accent3);
}

.selected_categories {
    background-color: var(--accent2);
}

.selected_groups {
    background-color: var(--accent3);
}

.categories,
.groups {
    color: var(--text1);
    background-color: var(--dark3);
    border-radius: 4px;
    padding: 1rem;

    display: flex;
    flex-direction: column;
}

.groups {
    padding: 0;
}

.groups > * {
    padding: 0.5rem;
}

.group {
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.groups > p,
.group > p {
    color: var(--text3);
}

.group:hover {
    background-color: var(--dark2);
}

.row {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 0.5rem;
}

.columns {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: max-content;
}

.description_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0.5rem;
    border-top: 1px solid var(--dark2);
}

.descriptions {
    flex: 1;
    min-width: 200px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    cursor: pointer;
    gap: 8px;

    background-color: var(--dark1);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.descriptions:hover {
    box-shadow:
        0px 0px 8px rgba(205, 193, 255, 0.2),
        0px 0px 21px rgba(205, 193, 255, 0.2),
        0px 0px 12px rgba(205, 193, 255, 0.2),
        0px 0px 7px rgba(205, 193, 255, 0.2),
        0px 0px 4px var(--dark3),
        0px 0px 2px rgba(205, 193, 255, 0.2);
}

.highlight {
    color: var(--accent2);
}

.divider {
    padding: 0 1px;
}
