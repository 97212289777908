.header {
    height: 64px;
    background-color: var(--dark1);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 1rem;
    border-bottom: 1px solid var(--dark2);
}

.icons {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--text1);
    gap: 1rem;
}

.borderRight {
    border-right: 1px solid var(--text1);
    padding-right: 1rem;
}

.notification {
    position: absolute;
    background-color: var(--danger);
    border-radius: 50%;
    width: 12px;
    height: 12px;
    top: -6px;
    right: -8px;
    color: var(--text1);
}
